<template>
    <div class="position-absolute w-100">
        <div
            class="event event_test position-relative"
            :class="eventStyleClass"
            @click.stop="handleEventClick()"
        >
            <div class="position-absolute w-100 h-100" v-if="event.class === `event`">
                <div class="event__container d-flex flex-column">
                    <div class="event__head" :class="{ 'd-none': hideContent && !event.isOpen }">
                        {{ event.title }}<br /><span class="event__type" v-if="event.isOpen">{{
                            subtitle
                        }}</span>
                    </div>
                    <div class="d-flex justify-content-end pe-1" :class="{ 'd-none': hideContent && !event.isOpen }">
                        <div
                            class="event__view_btn d-flex align-items-center"
                            @click.stop="handleViewEventClick()"
                            data-bs-toggle="tooltip"
                            data-bs-title="Default tooltip"
                        >
                            <i class="fas fa-edit"></i>
                        </div>
                        <div
                            class="event__view_btn text-danger ms-2 d-flex align-items-center"
                            @click.stop="handleCancelEventClick()"
                            data-bs-toggle="tooltip"
                            data-bs-title="Default tooltip"
                        >
                            <i class="fas fa-lg fa-times"></i>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end px-1 mt-auto">
                        <div class="event__footer ms-auto">{{ timeStart }} - {{ timeEnd }}</div>
                    </div>
                    <div class="event__close_btn p-1" @click.stop="handleEventCloseClick()">
                        <i class="fas fa-times"></i>
                    </div>
                </div>
            </div>
        </div>
        <ModalConfirm
            :modal-id="modalId"
            @onClose="closeModal()"
            @onConfirm="() => handleConfirmCancel()"
            >Va a cancelar <strong>{{ subtitle }}: {{ timeStart }} - {{ timeEnd }}</strong> a nombre
            de <strong>{{ event.title }}</strong></ModalConfirm
        >
    </div>
</template>

<script>
import { computed } from 'vue';
import { DateTime } from 'luxon';
import ModalConfirm from '@/components/modals/ModalConfirm.vue';
import { useVfm } from 'vue-final-modal';

export default {
    name: 'CalendarEvent',
    components: { ModalConfirm },
    props: {
        height: { type: Number, required: true },
        event: Object,
    },
    setup(props, { emit }) {
        const vfm = useVfm();
        const modalId = Symbol('modalId');

        const duration = computed(() => {
            const start = DateTime.fromISO(props.event.timeStart).toMillis();
            const end = DateTime.fromISO(props.event.timeEnd).toMillis();
            return (end - start) / 60000;
        });

        const hideContent = computed(
            () => duration.value * (props.height / 60) + Math.floor(duration.value / 60) <= 30
        );

        const styleProps = computed(() => {
            // console.log('props.event.timeEnd', props.event.timeEnd);
            const start = DateTime.fromISO(props.event.timeStart).toMillis();
            const end = DateTime.fromISO(props.event.timeEnd).toMillis();
            // eslint-disable-next-line operator-linebreak
            let bgColor;
            let closeColor = 'black';
            switch (props.event.type) {
                case 'consulta':
                    bgColor = '#3d79d5';
                    closeColor = '#0040a3';
                    break;
                case 'procedimiento':
                    bgColor = '#39c173';
                    closeColor = '#197440';
                    break;
                case 'blocked':
                    bgColor = '#d0d0d073';
                    closeColor = '#0040a3';
                    break;
                default:
                    bgColor = '#d0d0d073';
                    closeColor = '#0040a3';
                    break;
            }
            console.log('evento', props.event.paciente?.fullName);
            console.log('end', end);
            console.log('start', start);
            console.log('duration', duration.value);
            console.log(
                'height',
                `${duration.value} * (${props.height} / 60) + Math.floor(${duration.value} / 60)`
            );
            console.log(
                'height',
                duration.value * (props.height / 60) + Math.floor(duration.value / 60)
            );
            return {
                // height: `${duration * (60 / props.height) + Math.floor(duration / 60)}px`,
                height: `${
                    duration.value * (props.height / 60) + Math.floor(duration.value / 60)
                }px`,
                bgColor,
                closeColor,
            };
        });

        const timeStart = computed(
            () =>
                // eslint-disable-next-line comma-dangle, implicit-arrow-linebreak
                DateTime.fromISO(props.event.timeStart).toFormat('HH:mm')
            // eslint-disable-next-line function-paren-newline
        );

        const timeEnd = computed(
            () =>
                // eslint-disable-next-line comma-dangle, implicit-arrow-linebreak
                DateTime.fromISO(props.event.timeEnd).toFormat('HH:mm')
            // eslint-disable-next-line function-paren-newline
        );
        const handleEventClick = () => {
            emit('onEventClick', props.event);
        };
        const handleEventCloseClick = () => {
            emit('onEventCloseClick', props.event);
        };
        const handleViewEventClick = () => {
            emit('onViewEventClick', props.event);
        };
        const handleCancelEventClick = () => {
            vfm.open(modalId);
        };
        const handleConfirmCancel = () => {
            vfm.close(modalId);
            emit('onCancelEventClick', props.event);
        };

        const closeModal = () => {
            vfm.close(modalId);
        };

        const subtitle = computed(() => {
            if (props.event.type === 'procedimiento') {
                return props.event.procedimiento?.name || 'Procedimiento';
            }
            return 'Consulta';
        });

        const eventStyleClass = computed(() => {
            const classes = [];
            classes.push(`event-${props.event.class}`);
            if (props.event.isOpen) {
                classes.push('open');
            }

            return classes;
        });

        return {
            styleProps,
            timeStart,
            timeEnd,
            subtitle,
            eventStyleClass,
            handleEventClick,
            handleEventCloseClick,
            handleViewEventClick,
            handleCancelEventClick,
            handleConfirmCancel,
            modalId,
            closeModal,
            hideContent,
        };
    },
};
</script>

<style scoped>
.event {
    cursor: pointer;
    height: v-bind('styleProps.height');
    width: 100%;
    background-color: v-bind('styleProps.bgColor');
    z-index: 1;
    transition: all;
    transition-duration: 0.6s;
}

.event.event-event.open {
    cursor: inherit;
    height: inherit;
    width: 150%;
    z-index: 2;
    box-shadow: 1px 1px 5px 0.1px #0000003b;
}

.event__container {
    background-color: v-bind('styleProps.bgColor');
    min-height: 100%;
}

.event .event__head {
    font-size: 0.6em;
    color: white;
    background-color: v-bind('styleProps.bgColor');
    max-width: 100%;
    transition: all;
    transition-duration: 0.6s;
    padding-left: 3px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.event.event-event.open .event__head {
    font-size: 1em;
    width: calc(100% - 18px);
}

.event.event-event.open .event__head .event__type {
    font-size: 0.8em;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden !important;
    white-space: nowrap;
}

.event .event__footer {
    font-size: 0.6em;
    color: white;
    background-color: v-bind('styleProps.bgColor');
    transition: all;
    transition-duration: 0.6s;
}

.event.event-event.open .event__footer {
    font-size: 0.9em;
}

.event.event-event .event__close_btn {
    opacity: 0;
    z-index: 1;
    position: absolute;
    right: 2px;
    top: -3px;
    color: v-bind('styleProps.closeColor');
}

.event.event-event.open .event__close_btn {
    opacity: 1;
    cursor: pointer;
}

.event.event-event .event__view_btn {
    display: none;
    opacity: 0;
}

.event.event-event.open .event__view_btn {
    display: block;
    opacity: 1;
    cursor: pointer;
}
</style>
